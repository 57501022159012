export function BackgroundOverlayFullscreen({ onClick, style }) {
  return <button type="button" onClick={(event) => onClick(event)} className={`${style} modalIn`}></button>;
}

export function BackgroundOverlay({ onClick, style }) {
  return (
    <button
      type="button"
      onClick={(event) => onClick(event)}
      className={`${style ? style : ''} absolute z-40 h-screen w-full cursor-default`}
    ></button>
  );
}
