import { createRef, useRef, useState } from 'react';
import { getSession, signIn } from 'next-auth/react';
import { useRouter } from 'next/router';
import { FormInput } from './FormInput';
import { ValidateSignUp } from '../../helpers/Validate';
import { BackgroundOverlayFullscreen as ClickToCloseMax } from '../BackgroundOverlay';
import { useDispatch, useSelector } from 'react-redux';
import { updateSpinners } from '../../slices/spinners';
import { MyLink } from '../MyLink';
import { Loading } from '../Loading';
import { toast } from 'sonner';
import { H } from '@highlight-run/next/client';
import { CldImage } from 'next-cloudinary';
import ReCAPTCHA from 'react-google-recaptcha';
import Script from 'next/script';
import { useTranslation } from 'next-i18next';

const chars = {
  '/': '_',
  '"': '_',
  '§': '_',
  '!': '_',
  '*': '_',
  "'": '_',
  '´': '_',
  '{': '_',
  '}': '_',
  '|': '_',
  '\\': '_',
  '^': '_',
  '[': '_',
  ']': '_',
  '`': '_',
  ';': '_',
  '(': '_',
  ')': '_',
  '?': '_',
  ':': '_',
  '@': '_',
  '&': '_',
  '=': '_',
  '+': '_',
  $: '_',
  ',': '_',
  '<': '_',
  '>': '_',
  '#': '_',
  '%': '_',
};

export default function SignUpComponent({ csrfToken, setView }) {
  const router = useRouter();
  const { locale } = router;
  const [form, setForm] = useState({
    username: '',
    lastName: '',
    firstName: '',
    email: '',
    password: '',
    matchpassword: '',
    privacy: false,
    message: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [isValidated, setIsValidated] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState('');

  const recaptchaRef = createRef();
  const containerRef = useRef(null);
  const { t } = useTranslation('common');

  const dispatch = useDispatch();
  const spinners = useSelector((state) => state.spinners.spinners);

  const signupUser = async (event) => {
    const passForm = form;
    event.preventDefault();

    if (Object.keys(ValidateSignUp(passForm)).length === 0) {
      const signUpToast = toast.loading('registrieren...');
      try {
        // Execute the reCAPTCHA when the form is submitted

        dispatch(updateSpinners({ spinner8: true }));
        const res = await fetch('/api/register', {
          method: 'POST',
          body: JSON.stringify({
            username: form.username,
            firstName: form.firstName,
            lastName: form.lastName,
            email: form.email,
            password: form.password,
            matchpassword: form.matchpassword,
            reCaptchaToken: reCaptchaToken,
          }),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
        let user = await res.json();
        if (!res?.ok || res?.status == 400) {
          throw new Error(res?.message ?? user?.message);
        }
        if (user.message == 'success') {
          let options = {
            redirect: false,
            email: form.email,
            password: form.password,
          };
          const res = await signIn('credentials', options);
          const session = await getSession();
          toast.success(`${form.username} erfolgreich registriert!`, {
            id: signUpToast,
            duration: 7000,
          });
          if (session) {
            H.identify(session.token.email, {
              id: session?.token?.id,
              username: session?.token?.username,
            });
          }
          await router.push({
            pathname: '/verifiziere-email',
            query: {
              operation: 'signup',
            },
          });
          dispatch(updateSpinners({ spinner8: false }));
          setView('');
        }
        dispatch(updateSpinners({ spinner8: false }));
      } catch (error) {
        toast.warning(`${error}`, {
          id: signUpToast,
          duration: 7000,
        });
        setForm({ ...form, message: error });
        dispatch(updateSpinners({ spinner8: false }));
        console.error('Fehlgeschlagen', error);
      }
      return;
    } else {
      handleBeforeSignUpValidation();
    }
  };

  const handleClickToCloseModal = () => {
    setView('');
  };

  const handleChange = (event) => {
    const t = event.target;
    const name = t.name;
    const wert = t.value;
    const value = checkValues(name, wert);

    setForm({ ...form, [name]: value });
  };
  function checkValues(name, wert) {
    if (name === 'email') {
      return wert;
    }
    if (name === 'password') {
      return wert;
    }
    if (name === 'matchpassword') {
      return wert;
    }
    if (name === 'username') {
      let newWert = wert.replace(/[#+"§!'´()*<>%,$+=&@:?;\`\]\[\^\\|}{/\s]/g, (m) => chars[m] || '');
      return newWert;
    }
    return;
  }

  const handleBeforeSignUpValidation = (e) => {
    e?.preventDefault();
    const errors = ValidateSignUp(form);
    if (Object.keys(errors).length === 0) {
      setIsValidated(true);
      setFormErrors({});
    } else if (Object.keys(errors).length !== 0) {
      setFormErrors(errors);
      setIsValidated(false);
    }
  };

  const onReCAPTCHAChange = (captchaCode) => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!captchaCode) {
      return;
    }
    // Else reCAPTCHA was executed successfully so proceed here...
    setReCaptchaToken(captchaCode);
    // Reset the reCAPTCHA so that it can be executed again if user
    // submits another email.
    recaptchaRef.current.reset();
  };

  return (
    <>
      <Script src={`https://www.google.com/recaptcha/api.js?render=6LdLvY4pAAAAAO1KkffI7ggU5KgC_9FheG99JJaO}`} />
      <div className="absolute right-0 z-[101]">
        {/* <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
          onChange={onReCAPTCHAChange}
          badge="bottomright"
        /> */}
      </div>
      <div className="fixed inset-0 left-1/2 z-[110] m-auto flex max-h-[80%] w-full max-w-[1000px] -translate-x-1/2 transform items-center justify-center px-5 py-5 sm:max-h-[70%] sm:max-lg:w-[80%]">
        <div className="h-full w-full max-w-[1000px] overflow-hidden rounded-3xl md:h-auto">
          <div className="h-full w-full max-w-[1000px] overflow-y-auto bg-gray-100 text-gray-500 shadow-xl md:h-auto md:overflow-hidden">
            <div
              ref={containerRef}
              className="relative flex h-full w-full flex-col md:flex md:flex-row md:justify-between ">
              <div className="relative w-full flex-col md:flex-row lg:flex">
                {/* close */}
                <button
                  type="button"
                  onClick={() => handleClickToCloseModal()}
                  className="bg-site sticky top-3 z-50 ml-auto mr-5 block max-w-min cursor-pointer rounded-3xl border px-2 py-1 text-end text-sm font-semibold text-black drop-shadow-lg md:absolute md:right-0">
                  {t('close')}
                </button>
                {/* left side */}
                <div className="w-full basis-1/2 px-5 py-10 md:px-10">
                  {/* title */}
                  <div className="mb-5 text-center">
                    <p className="text-2xl font-bold text-gray-900">{t('signup_desc')}</p>
                  </div>
                  {/* form */}
                  <form
                    action=""
                    autoComplete="on"
                    onSubmit={(e) =>
                      Object.keys(formErrors)?.length === 0 && !isValidated
                        ? handleBeforeSignUpValidation(e)
                        : Object.keys(formErrors)?.length === 0 && isValidated
                        ? signupUser(e)
                        : handleBeforeSignUpValidation(e)
                    }>
                    <FormInput type="hidden" name="csrfToken" defaultValue={csrfToken} />
                    {/* Username */}
                    <FormInput
                      divClass={'w-full relative mb-1'}
                      beforeLabel={{ string: t('username'), css: 'px-1 text-xs font-semibold text-start' }}
                      className="input-login peer"
                      type="text"
                      id="username"
                      placeholder={t('username')}
                      name="username"
                      required
                      autoComplete="nickname"
                      // autoComplete="username"
                      minLength={4}
                      maxLength={20}
                      errorMessage={'Nicht korrekt! 4-20 Zeichen'}
                      value={form.username}
                      onChange={handleChange}></FormInput>
                    <span className="errormessage ">{formErrors.username}</span>
                    {/* Email */}
                    <FormInput
                      divClass={'w-full relative mb-1'}
                      beforeLabel={{ string: 'E-Mail', css: 'px-1 text-xs font-semibold text-start' }}
                      className="input-login peer"
                      type="email"
                      name="email"
                      id="email"
                      placeholder={'E-Mail'}
                      required
                      autoComplete="email"
                      pattern="^([^\s@]+@[^\s@]+\.[^\s@]+$)"
                      errorMessage={'Keine gültige E-Mail-Adresse'}
                      value={form.email}
                      onChange={handleChange}
                    />
                    <span className="errormessage">{formErrors.email}</span>
                    {/* Password */}
                    <FormInput
                      password={'w-full relative mb-1'}
                      beforeLabel={{ string: t('password'), css: 'px-1 text-start text-xs font-semibold' }}
                      className="input-login peer"
                      name="password"
                      id="password"
                      placeholder="•••••••••••••"
                      autoComplete="new-password"
                      required
                      maxLength="20"
                      minLength="8"
                      errorMessage={'Nicht korrekt! Zwischen 8-20 Zeichen'}
                      value={form.password}
                      onChange={handleChange}
                      containerRef={containerRef}
                    />
                    <span className="errormessage">{formErrors.password}</span>
                    <FormInput
                      password={'w-full relative mb-1'}
                      beforeLabel={{ string: t('confirm_password'), css: 'px-1 text-start text-xs font-semibold' }}
                      className="input-login peer"
                      name="matchpassword"
                      id="matchpassword"
                      placeholder="•••••••••••••"
                      required
                      autoComplete="off"
                      pattern={form.password}
                      errorMessage={'Password nicht identisch!'}
                      value={form.matchpassword}
                      onChange={handleChange}
                    />
                    <span className="errormessage mb-3">{formErrors.matchpassword}</span>
                    {/* checkbox */}
                    <div className="mb-5 text-xs">
                      <div className="flex flex-row items-center gap-2">
                        <input
                          type="checkbox"
                          className="h-5 w-5"
                          id="privacy"
                          value={true}
                          checked={form?.privacy ? true : false}
                          onChange={(event) =>
                            form.privacy
                              ? setForm((prev) => ({ ...form, privacy: '' }))
                              : setForm((prev) => ({ ...form, privacy: event.target.value }))
                          }
                        />
                        {locale == 'en' ? (
                          <label htmlFor="privacy" className="pl-2 text-start">
                            I've read the{' '}
                            <MyLink prefetch={false} href="/allgemeine-geschaeftsbedingungen">
                              <span className="cursor-pointer text-blue-600 underline">Terms of use</span>{' '}
                            </MyLink>
                            and the{' '}
                            <MyLink prefetch={false} href="/datenschutz">
                              <span className="cursor-pointer text-blue-600 underline">Privacy policy</span>{' '}
                            </MyLink>
                            .{' '}
                          </label>
                        ) : (
                          <label htmlFor="privacy" className="pl-2 text-start">
                            Ich habe die{' '}
                            <MyLink prefetch={false} href="/allgemeine-geschaeftsbedingungen">
                              <span className="cursor-pointer text-blue-600 underline">AGB</span>{' '}
                            </MyLink>
                            und die{' '}
                            <MyLink prefetch={false} href="/datenschutz">
                              <span className="cursor-pointer text-blue-600 underline">Datenschutzerklärung</span>{' '}
                            </MyLink>
                            gelesen.
                          </label>
                        )}
                      </div>
                      <span className="errormessage ml-3 text-xs">{formErrors.privacy}</span>
                    </div>
                    {/* Formerror */}
                    <p className="mb-2 w-full text-center text-xs">{form.message.toString()}</p>
                    {/* submit Button */}
                    {spinners?.spinner8 ? (
                      <div className="mx-auto mt-4 w-fit items-center justify-center">
                        <Loading style={'!w-6 !h-6'} />
                      </div>
                    ) : (
                      <div className="space-y-1">
                        {!reCaptchaToken ? (
                          <div className="flex justify-center">
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                              onChange={onReCAPTCHAChange}
                              badge="bottomright"
                            />
                          </div>
                        ) : (
                          <button
                            type="submit"
                            disabled={
                              Object.keys(formErrors)?.length === 0 && isValidated && spinners?.spinner8
                                ? true
                                : spinners?.spinner8
                            }
                            className="login-button">
                            {Object.keys(formErrors)?.length === 0 && !isValidated
                              ? t('check_inputs')
                              : Object.keys(formErrors)?.length === 0 && isValidated
                              ? t('signup_now')
                              : t('check_again')}
                          </button>
                        )}
                        <p className="h-2 w-full text-center"></p>
                        {/* google */}
                        <button
                          type="button"
                          onClick={() => {
                            if (!form.privacy) {
                              toast.warning(t('signup_err1'), { duration: 5000 });
                              setFormErrors({ privacy: t('signup_err1') });
                            } else {
                              signIn('google');
                            }
                          }}
                          className="login-button2 flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                          <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                            <path
                              d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                              fill="#EA4335"
                            />
                            <path
                              d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                              fill="#4285F4"
                            />
                            <path
                              d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                              fill="#FBBC05"
                            />
                            <path
                              d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                              fill="#34A853"
                            />
                          </svg>
                          <span className="text-sm font-semibold leading-6">{t('signup_w_google')}</span>
                        </button>
                      </div>
                    )}
                    {/* signin */}
                    <div className="mx-auto mt-2 flex w-full max-w-xs text-xs">
                      <span className="pr-2">{t('account_already_existing')}</span>
                      <button type="button" className="underline" onClick={() => setView('signin')}>
                        {t('signin')}
                      </button>
                    </div>
                  </form>
                </div>
                {/* right image */}
                <div className="relative hidden h-[360px] w-full basis-1/2 bg-white md:h-full md:max-h-[500px] md:w-1/2 lg:block">
                  {/* <div className="relative w-full"> */}
                  <div className="relative h-[800px] w-full">
                    <CldImage
                      src="static/e8qzpoq1pkdafljceyka"
                      objectFit="cover"
                      loading="eager"
                      layout="fill"
                      prefetch={true}
                      quality={100}
                      // width={600}
                      // height={800}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClickToCloseMax
        style={'bg-black/50 !mx-0  editModal z-[100] h-full'}
        onClick={() => handleClickToCloseModal()}
      />
    </>
  );
}
