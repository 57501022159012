import { getSession, signIn } from 'next-auth/react';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { FormInput } from './FormInput';
import { BackgroundOverlayFullscreen as ClickToCloseMax } from '../BackgroundOverlay';
import { useDispatch, useSelector } from 'react-redux';
import { updateRole, updateUser } from '../../slices/user';
import { updateSpinners } from '../../slices/spinners';
import { H } from '@highlight-run/next/client';
import { Loading } from '../Loading';
import { toast } from 'sonner';
import { CldImage } from 'next-cloudinary';
import { useTranslation } from 'next-i18next';

export default function SignInComponent({ csrfToken, setView }) {
  const [form, setForm] = useState({
    email: '',
    password: '',
    message: null,
  });
  const email = form.email;
  const password = form.password;
  const router = useRouter();
  const dispatch = useDispatch();
  const spinners = useSelector((state) => state.spinners.spinners);
  const { t } = useTranslation('common');

  const signinUser = async (event) => {
    event.preventDefault();
    const signInToast = toast.loading('Du wirst eingeloggt...');
    try {
      dispatch(updateSpinners({ spinner8: true }));
      let options = { redirect: false, email, password };
      const res = await signIn('credentials', options);
      const session = await getSession();
      setForm({ ...form, message: null });
      if (session) {
        toast.success(`Erfolgreich angemeldet. Aktualisiere...`, {
          id: signInToast,
          duration: 4000,
        });
        dispatch(updateUser(session.token));
        dispatch(updateRole(session.token.role));
        H.identify(session.token.email, {
          id: session?.token?.id,
          username: session?.token?.username,
        });
        if (!router.query.verify_token) {
          router.replace({
            query: { ...router.query, operation: 'signin' },
          });
        }
        setTimeout(() => {
          router.reload();
        }, 500);
      }
      if (res?.error) {
        dispatch(updateSpinners({ spinner8: false }));
        setForm({ ...form, message: res.error });
        toast.warning(`Fehlgeschlagen. "${res.error}"`, {
          id: signInToast,
          duration: 4000,
        });
      }
    } catch (error) {
      dispatch(updateSpinners({ spinner8: false }));
      toast.warning(`Fehlgeschlagen. Bitte erneut versuchen.`, {
        id: signInToast,
        duration: 4000,
      });
      console.error(error);
    }
  };

  const handleClickToCloseModal = () => {
    setView('');
  };
  const handleChange = (event) => {
    const t = event.target;
    const name = t.name;
    const wert = t.value;
    const value = checkValues(name, wert);
    setForm({ ...form, [name]: value });
  };

  function checkValues(name, wert) {
    if (name === 'email') {
      return wert;
    }
    if (name === 'password') {
      return wert;
    }
    return;
  }
  return (
    <>
      <div className="fixed inset-0 inset-x-0 left-1/2 z-[70] m-auto flex max-h-[80%] w-full max-w-[1000px] -translate-x-1/2 transform items-center justify-center px-5 py-5 sm:max-h-[65%] sm:max-lg:w-[80%]">
        <div className="h-full w-full max-w-[1000px] overflow-hidden rounded-3xl md:h-auto">
          <div className="h-full w-full max-w-[1000px] overflow-y-auto bg-gray-100 text-gray-500 shadow-xl md:h-auto md:overflow-hidden">
            <div className="relative h-full w-full flex-col md:flex-row md:justify-between lg:flex">
              <button
                type="button"
                onClick={() => handleClickToCloseModal()}
                className="bg-site sticky top-3 z-50 ml-auto mr-5 block max-w-min cursor-pointer rounded-3xl border px-2 py-1 text-end text-sm font-semibold text-black drop-shadow-lg md:absolute md:right-0">
                {t('close')}
              </button>
              <div className="relative w-full basis-1/2 flex-col md:flex md:flex-row ">
                {/* left side */}
                <div className="w-full px-5 py-10 md:px-10">
                  {/* title */}
                  <div className="mb-5 text-center">
                    <p className="text-2xl font-bold text-gray-900">{t('signin')}</p>
                  </div>
                  {/* form */}
                  <form name="signin" action="" noValidate autoComplete="on" className="" onSubmit={signinUser}>
                    <FormInput type="hidden" name="csrfToken" defaultValue={csrfToken} />
                    {/* Email */}
                    <FormInput
                      divClass={'w-full relative mb-1'}
                      beforeLabel={{ string: 'E-Mail', css: 'px-1 text-xs font-semibold text-start' }}
                      className="input-login peer"
                      type="email"
                      name="email"
                      id="userEmail"
                      placeholder="E-Mail"
                      required
                      autoComplete="email"
                      pattern="^([^\s@]+@[^\s@]+\.[^\s@]+$)"
                      errorMessage={'Keine gültige E-Mail-Adresse'}
                      onChange={handleChange}
                    />
                    {/* Password */}
                    <FormInput
                      password={'w-full relative'}
                      signin
                      beforeLabel={{ string: t('password'), css: 'px-1 text-xs font-semibold text-start' }}
                      className="input-login peer"
                      autoComplete="current-password"
                      name="password"
                      id="password"
                      placeholder="•••••••••••••"
                      required
                      errorMessage={'Ungültiges Format! Nur (a-zA-Z-0-9-!äöü#@.,-_) und 8-20 Zeichen erlaubt'}
                      onChange={handleChange}
                    />
                    {/* Formerror */}
                    <p className="errormessage mb-7">{form.message}</p>
                    {/* Button */}
                    {spinners?.spinner8 ? (
                      <div className="flex w-full justify-center">
                        <Loading style={'!w-6 !h-6'} />
                      </div>
                    ) : (
                      <div className="space-y-1">
                        <button className="login-button" type="submit">
                          {t('signin')}
                        </button>{' '}
                        <p className="h-2 w-full text-center"></p>
                        {/* google */}
                        <button
                          type="button"
                          onClick={() => signIn('google')}
                          className="login-button2 flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">
                          <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                            <path
                              d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                              fill="#EA4335"
                            />
                            <path
                              d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                              fill="#4285F4"
                            />
                            <path
                              d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                              fill="#FBBC05"
                            />
                            <path
                              d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                              fill="#34A853"
                            />
                          </svg>
                          <span className="text-sm font-semibold leading-6">{t('signin_w_google')}</span>
                        </button>
                      </div>
                    )}
                    <div className="mt-2 flex w-full justify-center gap-2 text-xs">
                      <button type="button" className="underline" onClick={() => setView('signup')}>
                        {t('signup')}
                      </button>
                      <span className="">|</span>
                      <button type="button" className="flex text-xs underline" onClick={() => setView('resetpassword')}>
                        <p className="cursor-pointer">{t('forgot_password')}</p>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              {/* right image */}
              <div className="relative hidden h-[360px] w-full basis-1/2 bg-white md:h-full md:max-h-[500px] md:w-1/2 lg:block">
                {/* <div className="relative w-full"> */}
                <div className="relative h-[800px] w-full">
                  <CldImage
                    src="static/yomzh6eg5tba6bdzyd6q"
                    objectFit="cover"
                    objectPosition={'center'}
                    layout="fill"
                    prefetch={true}
                    loading="eager"
                    quality={100}
                    // width={750}
                    // height={800}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClickToCloseMax style={'bg-black/50 !mx-0  editModal z-[60] h-full'} onClick={() => handleClickToCloseModal()} />
    </>
  );
}
