import { useRef, useState } from 'react';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { MyLink } from '../MyLink';
import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import HeroSearchFormSmall from './HeroSearchFormSmall/HeroSearchFormSmall';
import { useOutsideAlerter } from '../../utils';
import HeroSearchForm2Mobile from './HeroSearchForm2Mobile/HeroSearchForm2Mobile';
import { useDispatch, useSelector } from 'react-redux';
import { updateModalstate } from '../../slices/activateModal';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useMediaQuery, useTheme } from '@mui/material';
import dynamic from 'next/dynamic';
import { TooltipWrapper } from '../TooltipWrapper';
import { Tooltip, Zoom } from '@mui/material';
import SignInComponent from '../Forms/SignInFormModal';
import SignUpComponent from '../Forms/SignUpFormModal';
import { getCookie, setCookie } from 'cookies-next';
import { v4 as uuidv4 } from 'uuid';
import { Icon2, Icon3, Icon4, Icon8, Logo, LogoFull } from '../Icons';
import { HeaderUsermenuUnauthenticated } from './HeaderUsermenuUnauthenticated';
import { LocalisationSelect } from './LocalisationSelect';
import { useTranslation } from 'next-i18next';

// import ResetPasswordComponent from '../Forms/ResetPasswordFormModal';
const DynamicResetPasswordComponent = dynamic(() => import('../Forms/ResetPasswordFormModal'), {
  ssr: true,
});

let WIN_PREV_POSITION = 0;
if (typeof window !== 'undefined') {
  WIN_PREV_POSITION = window.pageYOffset;
}

function HeaderStatic({ chat, dashboard, detailpage, setMobileMenuOpen }) {
  const [view, setView] = useState('');
  const [showHeroSearch, setShowHeroSearch] = useState(null);
  const [currentTab, setCurrentTab] = useState('Dienstleistungen');
  //
  const { data: session, status } = useSession();
  const dispatch = useDispatch();
  const activateModal = useSelector((state) => state.activateModal.activateModal);
  //
  const headerInnerRef = useRef(null);
  const router = useRouter();
  const { t } = useTranslation('common');
  const { locale, locales, defaultLocale, query } = router;

  let headerClassName = '';
  let pathname = router.asPath;

  const theme = useTheme();
  const isMobileTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isDownMd = useMediaQuery(theme.breakpoints.down('768'));

  const preloadImages = () => {
    const signinimg = new Image();
    signinimg.src = 'https://res.cloudinary.com/drt9lfnfg/image/upload/v1714683129/static/yomzh6eg5tba6bdzyd6q.png';
    const signupimg = new Image();
    signupimg.src = 'https://res.cloudinary.com/drt9lfnfg/image/upload/v1714682736/static/e8qzpoq1pkdafljceyka.png';
  };

  // for headerSearchForm
  useOutsideAlerter(headerInnerRef, () => {
    setShowHeroSearch(null);
    setCurrentTab('Dienstleistungen');
  });
  const handleEvent = () => {
    window.requestAnimationFrame(handleHideSearchForm);
  };
  const handleHideSearchForm = () => {
    if (!document.querySelector('#nc-Header-3-anchor')) {
      return;
    }
    //
    let currentScrollPos = window.pageYOffset;
    if (WIN_PREV_POSITION - currentScrollPos > 100 || WIN_PREV_POSITION - currentScrollPos < -100) {
      setShowHeroSearch(null);
    } else {
      return;
    }
    WIN_PREV_POSITION = currentScrollPos;
  };

  // close herosearchform on routechange
  useEffect(() => {
    setShowHeroSearch(null);
  }, [pathname]);
  //
  useEffect(() => {
    preloadImages();
  }, []);

  // preload imgs + HIDE WHEN SCROLL EVENT
  useEffect(() => {
    window.addEventListener('scroll', handleEvent);
    return () => {
      window.removeEventListener('scroll', handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // document overflow-hidden on viewstate
  useEffect(() => {
    if (view) document.documentElement.classList.add('overflow-hidden');
    else document.documentElement.classList.remove('overflow-hidden');
  }, [view]);

  // handle WebNav z-index in Dashboard && setShowHeroSearch to false when resizing window < md
  useEffect(() => {
    if (isDownMd) setShowHeroSearch(null);
    if (!isDownMd && showHeroSearch) dispatch(updateModalstate({ headerHeroSearchFormLG: true }));
    else if (!isDownMd && !showHeroSearch && activateModal.headerHeroSearchFormLG) {
      dispatch(updateModalstate({ headerHeroSearchFormLG: false }));
    }
  }, [isDownMd, showHeroSearch]);

  // handle opening global modals & set cookies
  useEffect(() => {
    if (activateModal.signup) {
      preloadImages();
      setView('signup');
      if (view === 'signup') {
        dispatch(updateModalstate({ signup: false }));
      }
    }
    if (activateModal.signin) {
      preloadImages();
      setView('signin');
      if (view === 'signin') {
        dispatch(updateModalstate({ signin: false }));
      }
    }
    if (activateModal.resetpassword) {
      setView('resetpassword');
      if (view === 'resetpassword') {
        dispatch(updateModalstate({ resetpassword: false }));
      }
    }
    if (query && query.token && query.email) {
      dispatch(updateModalstate({ resetpassword: true }));
    }
    if (query && query.show_signup) {
      router.replace('');
      dispatch(updateModalstate({ signup: true }));
    }
    if (query && query.show_signin) {
      router.replace('');
      dispatch(updateModalstate({ signin: true }));
    }
    // set cookie
    const uniqueUserID = getCookie('uniqueUserID');
    if (query && query.referral) {
      setCookie('referralId', query.referral, { maxAge: 30 * 24 * 60 * 60, path: '/', sameSite: 'None', secure: true });
      router.replace('');
      dispatch(updateModalstate({ signup: true }));
    }
    if (!uniqueUserID) {
      const uniqueUserId = uuidv4();
      // setCookie('uniqueUserID', uniqueUserId, { maxAge: 30 * 24 * 60 * 60, path: '/', sameSite: 'None' });
      setCookie('uniqueUserID', uniqueUserId, { maxAge: 30 * 24 * 60 * 60, path: '/', sameSite: 'None', secure: true });
    }
  }, [activateModal.signin, view, query, activateModal.signup, activateModal.resetpassword]);

  //opened search form modal
  const renderHeroSearch = () => {
    return (
      // will-change-[transform,opacity]
      <div
        className={`absolute inset-x-0 top-0 z-10 transition-all ${
          showHeroSearch
            ? 'visible'
            : 'pointer-events-none invisible -translate-x-0 -translate-y-[90px] scale-y-[0.6] opacity-0 lg:scale-x-[0.395]'
        }`}>
        <div className={`mx-auto w-full pb-6 lg:max-w-4xl`}>
          <HeroSearchFormSmall
            defaultFieldFocus={showHeroSearch || undefined}
            onTabChange={setCurrentTab}
            defaultTab={currentTab}
          />
        </div>
      </div>
    );
  };
  //component click to open search > md:
  const renderButtonOpenHeroSearch = () => {
    return (
      <div
        className={`relative flex w-full items-center justify-between rounded-full border border-neutral-200 bg-white/80 shadow transition-all hover:shadow-md ${
          showHeroSearch
            ? 'pointer-events-none invisible -translate-x-0 translate-y-20 scale-y-[1.8] opacity-0 lg:scale-x-[2.55]'
            : 'visible'
        }`}>
        <div className="flex items-center text-sm font-medium">
          <span onClick={() => setShowHeroSearch('location')} className="block cursor-pointer truncate py-3 pl-5 pr-4">
            {t('location')}
          </span>
          <span className="h-5 w-[1px] bg-neutral-300 "></span>
          <span onClick={() => setShowHeroSearch('dates')} className="block cursor-pointer truncate px-4 py-3 ">
            {t('starttime')}
          </span>
          <span className="h-5 w-[1px] bg-neutral-300 "></span>
          <span
            onClick={() => {
              setShowHeroSearch('guests');
            }}
            className="block cursor-pointer truncate px-4 py-3 font-normal">
            {t('add_guests')}
          </span>
        </div>

        <div className="ml-auto flex-shrink-0 cursor-pointer pr-2" onClick={() => setShowHeroSearch('location')}>
          <span className="flex h-8 w-8 items-center justify-center rounded-full bg-indigo-500  text-white">
            <MagnifyingGlassIcon className="h-5 w-5" />
          </span>
        </div>
      </div>
    );
  };

  let { scrollY } = useScroll();
  let bgOpacityLight = useTransform(scrollY, [0, 72], [0.5, 0.9]);

  return (
    <>
      {/* for scroll to top */}
      <div id="top"></div>
      {/* for dark background overlay */}
      {showHeroSearch && (
        <div
          className={`nc-Header nc-Header-3 fixed inset-0 top-0 z-40 bg-black/30 transition-opacity will-change-[opacity]`}
        />
      )}
      {/* for ? */}
      {showHeroSearch && <div id="nc-Header-3-anchor"></div>}

      <header
        ref={headerInnerRef}
        className={`fixed top-0 ${activateModal?.generalModal ? ' z-20 ' : ' z-50 '} w-full ${headerClassName}`}>
        {/* header backdrop */}
        <motion.div
          style={{
            '--bg-opacity-light': showHeroSearch ? 1 : bgOpacityLight,
            zIndex: showHeroSearch ? '10' : 0,
          }}
          className={`absolute inset-x-0 top-0 h-full backdrop-blur-sm transition-transform will-change-[transform,opacity]
          ${showHeroSearch ? ' duration-75 ' : ' '} ${
            detailpage || dashboard ? ' bg-white' : ' bg-white/[var(--bg-opacity-light)] '
          } 
          ${
            showHeroSearch ? (currentTab === 'Dienstleistungen' ? 'scale-y-[4.4]' : 'scale-y-[3.4]') : ''
          }`}></motion.div>
        {/* header content */}
        <div className="relative flex h-[88px] justify-between xl:container lg:px-4 2xl:gap-10">
          {/* left side  */} {/* Logo + Quick Links Or Dashboard Menu  */}
          {!dashboard ? (
            <div className="relative hidden flex-1 cursor-default items-center justify-between md:flex">
              <MyLink prefetch={false} href="/" className="max-md:hidden md:max-lg:pl-5 lg:hidden">
                <Logo className="h-12 w-10 fill-[#0f3c69]" />
              </MyLink>
              <MyLink prefetch={false} href="/" className="max-lg:hidden md:max-lg:pl-5">
                <LogoFull height={50} width={150} className="-mr-10 fill-[#0f3c69]" />
              </MyLink>
              {/* quick links text */}
              <div className="flex w-full justify-center gap-3 pr-5 max-lg:hidden lg:gap-6">
                <MyLink
                  prefetch={false}
                  rel="nofollow"
                  aria-label="Lokale Dienstleistungen"
                  href='/lokale-leistungen?filters=%7B"location"%3A""%7D&page=1'
                  className="flex gap-1 text-sm max-lg:hidden">
                  <Icon3 className="h-5 w-5 fill-black" />
                  {t('local')}
                  <span className="-ml-1 lg:max-2xl:hidden">
                    {locale == 'de' && 'e'} {t('services')}
                  </span>
                </MyLink>
                <MyLink
                  prefetch={false}
                  rel="nofollow"
                  aria-label="Online Dienstleistungen"
                  href='/online-leistungen?filters=%7B"search"%3A""%2C"priceMin"%3A5%2C"priceMax"%3A9999%2C"sortBy"%3A"Neu+hinzugefügt"%7D&page=1'
                  className="flex gap-1 text-sm max-lg:hidden lg:max-xl:items-center">
                  <Icon2 className="h-5 w-5 fill-black" />
                  Online<span className="lg:max-2xl:hidden"> {t('services')}</span>
                </MyLink>
                <MyLink
                  prefetch={false}
                  rel="nofollow"
                  aria-label="Lokale Dienstleistungen"
                  href="/tonstudios?page=1"
                  className="flex gap-1 text-sm max-xl:hidden">
                  <Icon4 className="h-5 w-5 fill-black" />
                  {t('recording_studios')}
                </MyLink>
              </div>
              {/* Quick Links icons*/}
              <div className="flex w-full grow items-center justify-center gap-2 text-xs lg:hidden xl:gap-3 2xl:justify-end">
                <Tooltip
                  title={<p className="whitespace-nowrap text-sm">Lokale Dienstleistungen</p>}
                  placement={'top'}
                  className="text-xl"
                  arrow
                  enterTouchDelay={0}
                  leaveTouchDelay={2500}
                  TransitionComponent={Zoom}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        display: { xs: 'none', sm: 'block' },
                        bgcolor: 'grey.800',
                        '& .MuiTooltip-arrow': {
                          color: 'grey.800',
                        },
                      },
                    },
                  }}
                  PopperProps={{
                    disablePortal: true,
                    popperOptions: {
                      positionFixed: true,
                      modifiers: {
                        preventoverflow: {
                          enabled: true,
                          boundarieselement: headerInnerRef, // where "window" is the boundary
                        },
                      },
                    },
                  }}>
                  <TooltipWrapper>
                    <MyLink
                      prefetch={false}
                      rel="nofollow"
                      aria-label="Lokale Dienstleistungen"
                      href='/lokale-leistungen?filters=%7B"location"%3A""%7D&page=1'
                      className="flex items-center">
                      <button type="button" className="text-primary">
                        <Icon3 className="h-7 w-7 fill-black" />
                      </button>
                    </MyLink>
                  </TooltipWrapper>
                </Tooltip>
                <Tooltip
                  title={<p className="whitespace-nowrap text-sm">Online Dienstleistungen</p>}
                  placement={'top'}
                  className="text-xl"
                  arrow
                  enterTouchDelay={0}
                  leaveTouchDelay={2500}
                  TransitionComponent={Zoom}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        display: { xs: 'none', sm: 'block' },
                        bgcolor: 'grey.800',
                        '& .MuiTooltip-arrow': {
                          color: 'grey.800',
                        },
                      },
                    },
                  }}
                  PopperProps={{
                    disablePortal: true,
                    popperOptions: {
                      positionFixed: true,
                      modifiers: {
                        preventoverflow: {
                          enabled: true,
                          boundarieselement: headerInnerRef, // where "window" is the boundary
                        },
                      },
                    },
                  }}>
                  <TooltipWrapper>
                    <MyLink
                      prefetch={false}
                      rel="nofollow"
                      aria-label="Online Dienstleistungen"
                      href='/online-leistungen?filters=%7B"search"%3A""%2C"priceMin"%3A5%2C"priceMax"%3A9999%2C"sortBy"%3A"Neu+hinzugefügt"%7D&page=1'
                      className="flex items-center">
                      <button type="button" className="text-primary">
                        <Icon2 className="h-7 w-7 fill-black" />
                      </button>
                    </MyLink>
                  </TooltipWrapper>
                </Tooltip>
                <Tooltip
                  title={<p className="whitespace-nowrap text-sm">Tonstudios</p>}
                  placement={'top'}
                  className="text-xl"
                  arrow
                  enterTouchDelay={0}
                  leaveTouchDelay={2500}
                  TransitionComponent={Zoom}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        display: { xs: 'none', sm: 'block' },
                        bgcolor: 'grey.800',
                        '& .MuiTooltip-arrow': {
                          color: 'grey.800',
                        },
                      },
                    },
                  }}
                  PopperProps={{
                    disablePortal: true,
                    popperOptions: {
                      positionFixed: true,
                      modifiers: {
                        preventoverflow: {
                          enabled: true,
                          boundarieselement: headerInnerRef, // where "window" is the boundary
                        },
                      },
                    },
                  }}>
                  <TooltipWrapper>
                    <MyLink
                      prefetch={false}
                      rel="nofollow"
                      aria-label="Tonstudioprofile"
                      href='/tonstudios?filters=%7B"studioType"%3A""%2C"location"%3A""%7D&page=1'
                      className="flex items-center">
                      <button type="button" className="text-primary">
                        <Icon4 className="h-7 w-7 fill-black" />
                      </button>
                    </MyLink>
                  </TooltipWrapper>
                </Tooltip>
              </div>
            </div>
          ) : isMobileTablet || chat ? (
            <button
              type="button"
              aria-label="Seiten-Menü"
              className="relative z-[999] h-full border-r border-gray-200 px-4 text-gray-500 outline-none sm:px-6"
              onClick={() => setMobileMenuOpen(true)}>
              <span className="sr-only">Sidebar öffnen</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          ) : null}
          {/* middle */} {/* search button */}
          <div className="mx-auto flex flex-[2] md:flex-none">
            {/* big search */}
            <div className="hidden flex-1 self-center md:flex">
              {renderButtonOpenHeroSearch()}
              {/* opened state search */}
              {renderHeroSearch()}
            </div>
            {/* mobile search */}
            <div className="mx-auto w-full max-w-[90%] flex-1 self-center rounded-full drop-shadow-lg sm:max-w-lg md:hidden">
              <HeroSearchForm2Mobile dashboard={dashboard} />
            </div>
          </div>
          {/* right side  */} {/* NAV >:md  */}
          <div className={`relative hidden flex-1 justify-between max-lg:justify-end md:flex 2xl:gap-10`}>
            {/* Quick Links icons*/}
            {/* <div className="flex w-full grow items-center justify-center gap-2 text-xs max-lg:hidden xl:gap-3 2xl:justify-end">
              <Tooltip
                title={<p className="whitespace-nowrap text-sm">Online Dienstleistungen</p>}
                placement={'top'}
                className="text-xl"
                arrow
                enterTouchDelay={0}
                leaveTouchDelay={2500}
                TransitionComponent={Zoom}
                componentsProps={{
                  tooltip: {
                    sx: {
                      display: { xs: 'none', sm: 'block' },
                      bgcolor: 'grey.800',
                      '& .MuiTooltip-arrow': {
                        color: 'grey.800',
                      },
                    },
                  },
                }}
                PopperProps={{
                  disablePortal: true,
                  popperOptions: {
                    positionFixed: true,
                    modifiers: {
                      preventoverflow: {
                        enabled: true,
                        boundarieselement: headerInnerRef, // where "window" is the boundary
                      },
                    },
                  },
                }}>
                <TooltipWrapper>
                  <MyLink
                    prefetch={false}
                    aria-label="Online Dienstleistungen"
                    rel="nofollow"
                    href='/online-leistungen?filters=%7B"search"%3A""%2C"priceMin"%3A5%2C"priceMax"%3A9999%2C"sortBy"%3A"Neu+hinzugefügt"%7D&page=1'
                    className="flex items-center">
                    <button type="button" className="text-primary">
                      <Icon2 className="h-7 w-7 fill-black" />
                    </button>
                  </MyLink>
                </TooltipWrapper>
              </Tooltip>
              <Tooltip
                title={<p className="whitespace-nowrap text-sm">Lokale Dienstleistungen</p>}
                placement={'top'}
                className="text-xl"
                arrow
                enterTouchDelay={0}
                leaveTouchDelay={2500}
                TransitionComponent={Zoom}
                componentsProps={{
                  tooltip: {
                    sx: {
                      display: { xs: 'none', sm: 'block' },
                      bgcolor: 'grey.800',
                      '& .MuiTooltip-arrow': {
                        color: 'grey.800',
                      },
                    },
                  },
                }}
                PopperProps={{
                  disablePortal: true,
                  popperOptions: {
                    positionFixed: true,
                    modifiers: {
                      preventoverflow: {
                        enabled: true,
                        boundarieselement: headerInnerRef, // where "window" is the boundary
                      },
                    },
                  },
                }}>
                <TooltipWrapper>
                  <MyLink
                    prefetch={false}
                    rel="nofollow"
                    aria-label="Lokale Dienstleistungen"
                    href='/lokale-leistungen?filters=%7B"location"%3A""%7D&page=1'
                    className="flex items-center">
                    <button type="button" className="text-primary">
                      <Icon3 className="h-7 w-7 fill-black" />
                    </button>
                  </MyLink>
                </TooltipWrapper>
              </Tooltip>
              <Tooltip
                title={<p className="whitespace-nowrap text-sm">Tonstudios</p>}
                placement={'top'}
                className="text-xl"
                arrow
                enterTouchDelay={0}
                leaveTouchDelay={2500}
                TransitionComponent={Zoom}
                componentsProps={{
                  tooltip: {
                    sx: {
                      display: { xs: 'none', sm: 'block' },
                      bgcolor: 'grey.800',
                      '& .MuiTooltip-arrow': {
                        color: 'grey.800',
                      },
                    },
                  },
                }}
                PopperProps={{
                  disablePortal: true,
                  popperOptions: {
                    positionFixed: true,
                    modifiers: {
                      preventoverflow: {
                        enabled: true,
                        boundarieselement: headerInnerRef, // where "window" is the boundary
                      },
                    },
                  },
                }}>
                <TooltipWrapper>
                  <MyLink
                    prefetch={false}
                    rel="nofollow"
                    aria-label="Tonstudioprofile"
                    href='/tonstudios?filters=%7B"studioType"%3A""%2C"location"%3A""%7D&page=1'
                    className="flex items-center">
                    <button type="button" className="text-primary">
                      <Icon4 className="h-7 w-7 fill-black" />
                    </button>
                  </MyLink>
                </TooltipWrapper>
              </Tooltip>
            </div> */}
            <div className="flex w-full items-center justify-end space-x-1 text-xs max-lg:px-2">
              {/* register/signin Or dashboard Btn */}
              {session?.token?.id && (status == 'authenticated' || status == 'loading') ? (
                <>
                  <LocalisationSelect />
                  <Tooltip
                    title={<p className="whitespace-nowrap text-sm">Dashboard</p>}
                    placement={'top'}
                    className="text-xl"
                    arrow
                    enterTouchDelay={0}
                    leaveTouchDelay={2500}
                    TransitionComponent={Zoom}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          display: { xs: 'none', sm: 'block' },
                          bgcolor: 'grey.800',
                          '& .MuiTooltip-arrow': {
                            color: 'grey.800',
                          },
                        },
                      },
                    }}
                    PopperProps={{
                      disablePortal: true,
                      popperOptions: {
                        positionFixed: true,
                        modifiers: {
                          preventoverflow: {
                            enabled: true,
                            boundarieselement: headerInnerRef, // where "window" is the boundary
                          },
                        },
                      },
                    }}>
                    <TooltipWrapper>
                      <MyLink prefetch={false} aria-label="Dashboard" rel="nofollow" href="/dashboard">
                        <button
                          type="button"
                          className="group ml-3 rounded-full border border-indigo-600 bg-transparent px-2.5 py-2.5 font-semibold text-indigo-600 transition duration-200">
                          <Icon8 className="h-7 w-7 fill-black" />
                        </button>
                      </MyLink>
                    </TooltipWrapper>
                  </Tooltip>
                </>
              ) : (
                <>
                  <MyLink
                    prefetch={false}
                    aria-label="Dashboard"
                    rel="nofollow"
                    href="/fuer-anbieter"
                    className="px-2.5 py-2.5 text-sm font-semibold text-indigo-600 max-lg:hidden">
                    {t('become_seller')}
                  </MyLink>
                  <LocalisationSelect />
                  <HeaderUsermenuUnauthenticated headerInnerRef={headerInnerRef} setView={setView} />
                </>
              )}
              {/* <MenuBar unreadCount={unreadCount} setView={setView} view={view} /> */}
            </div>
          </div>
        </div>

        {/* Global Modals */}
        <div className={view === 'signin' ? '' : 'hidden'}>
          <SignInComponent setView={setView} />
        </div>
        <div className={view === 'signup' ? '' : 'hidden'}>
          <SignUpComponent setView={setView} />
        </div>

        {view === 'resetpassword' ? <DynamicResetPasswordComponent setView={setView} /> : null}
      </header>
    </>
  );
}

export default HeaderStatic;
