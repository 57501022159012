import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { MyLink } from '../MyLink';
import Avatar from '../Avatar';
import { useDispatch } from 'react-redux';
import { Icon14, Icon21 } from '../Icons';
import { updateModalstate } from '../../slices/activateModal';
import { useTranslation } from 'next-i18next';
import { CldImage } from 'next-cloudinary';

const imageUrls = [
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722663/static/avatars/l53praw4cyqahbklzbgr.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722663/static/avatars/vrichlf1hv97qvsyygfh.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722662/static/avatars/uobijkkaixop4et69lia.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722662/static/avatars/eqzajtrejt5uo42uxrf1.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722662/static/avatars/uiunmgksdmtfbsi0rrgh.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722662/static/avatars/qriwkcfvo4l3gym8xcjf.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722662/static/avatars/jupzo2iocrjjrvdphnkc.png',
  'https://res.cloudinary.com/drt9lfnfg/image/upload/v1717722661/static/avatars/zoktatbz9yqu4sv5yytp.png',
];

export function HeaderUsermenuUnauthenticated(props) {
  const [isOpen, setIsOpen] = useState(false);
  //
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  return (
    <Popover className={`relative`}>
      {({ open, close }) => (
        <>
          <Popover.Button
            className="ml-3 flex items-center py-3 text-indigo-900"
            onMouseEnter={() => setIsOpen(true)}
            onMouseLeave={() => setIsOpen(false)}>
            <Icon14 className="h-7 w-7 fill-indigo-600" />
          </Popover.Button>

          <Transition as={Fragment} show={isOpen}>
            <Popover.Panel
              className="absolute right-0 top-[90%] z-10 mt-1 max-h-60 w-screen max-w-[260px] overflow-auto rounded-3xl bg-white px-4 py-1 text-base shadow-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:px-0 sm:text-sm"
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}>
              <div className="relative grid grid-cols-1 gap-4 bg-white px-6 py-7  ">
                {/* avatar */}
                <div className="flex items-center space-x-3">
                  <Avatar sizeClass="w-12 h-12" />
                  <div className="flex-grow">
                    <h4 className="line-clamp-1 text-xl font-semibold">Hey 👋🎧</h4>
                    {/* <p className="mt-0.5 text-xs">Los Angeles, CA</p> */}
                  </div>
                </div>

                {/* <div className="w-full border-b border-neutral-200  " /> */}
                <div className="flex gap-2">
                  <button
                    type="button"
                    onClick={() => dispatch(updateModalstate({ signup: true }))}
                    className="group w-full rounded-3xl border border-indigo-500 bg-indigo-500 px-3 py-2 font-semibold text-white transition duration-200 hover:scale-105">
                    <span className="transition duration-300 group-hover:drop-shadow-sm">{t('signup')}</span>
                  </button>
                  <button
                    type="button"
                    onClick={() => dispatch(updateModalstate({ signin: true }))}
                    className="group w-full rounded-3xl border-2 border-black px-3 py-2 font-semibold text-black transition duration-200 hover:scale-105">
                    <span className="transition duration-300 group-hover:drop-shadow-sm">{t('signin')}</span>
                  </button>
                </div>

                <div className="w-full border-b border-neutral-200" />

                <MyLink
                  prefetch={false}
                  href={'https://hilfe.esfaras.de/'}
                  className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-neutral-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50  "
                  onClick={() => close()}>
                  <div className="flex flex-shrink-0 items-center justify-center text-black">
                    <Icon21 className="h-6 w-6 fill-black" />
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-medium">{t('helpcenter')}</p>
                  </div>
                </MyLink>
              </div>
            </Popover.Panel>
          </Transition>
          <span className="hidden">
            <CldImage src={imageUrls[0]} width={150} height={150} objectFit="cover" alt="avatar" />
            <CldImage src={imageUrls[1]} width={150} height={150} objectFit="cover" alt="avatar" />
            <CldImage src={imageUrls[2]} width={150} height={150} objectFit="cover" alt="avatar" />
            <CldImage src={imageUrls[3]} width={150} height={150} objectFit="cover" alt="avatar" />
            <CldImage src={imageUrls[4]} width={150} height={150} objectFit="cover" alt="avatar" />
            <CldImage src={imageUrls[5]} width={150} height={150} objectFit="cover" alt="avatar" />
            <CldImage src={imageUrls[6]} width={150} height={150} objectFit="cover" alt="avatar" />
            <CldImage src={imageUrls[7]} width={150} height={150} objectFit="cover" alt="avatar" />
          </span>
        </>
      )}
    </Popover>
  );
}
